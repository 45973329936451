<template>
  <div class="home">
    <van-sticky>
      <div class="header">
        <img class="header-logo" src="@/assets/images/logo.png" alt="">
        <div class="make-btn" @click="onChange(true, 'makeShow')">
          <span>预约演示</span>
        </div>
      </div>
    </van-sticky>
    <div class="banner">
      <van-swipe :autoplay="5000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in bannerList" :key="index">
          <img class="swipe-img" :src="item" alt="">
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="one-chunck chunck chunck-bg">
      <div class="chunck-title">
        <span>聚优企能为您提供的服务</span>
      </div>
      <div class="system-ul">
        <div class="system-li" v-for="(item, index) in serveList" :key="index">
          <div class="li-title">
            <img class="li-img" :src="item.logoUrl" alt="">
            <span>{{ item.name }}</span>
          </div>
          <div class="word" :id="`word-cloud${index}`"></div>
          <div class="btn g-a-c">
            <div class="btn-make g-a-c" @click="onChange(true, 'makeShow')">
              <span>预约演示</span>
            </div>
            <div class="btn-tyan g-a-c" @click="onChange(true, 'registerShow')">
              <span>立即体验</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="two-chunck chunck chunck-bg">
      <div class="chunck-title">
        <span>智能化</span>
        <span>给您的工作提供更多可能性、更便捷、更高效</span>
      </div>
      <div class="int-c">
        <div class="int-bar">
          <div :class="['bar-li', intIndex == item.code ? 'li-on':'']" v-for="(item, index) in intList" :key="index" @click="modeClick(item, 'intIndex')">
            <span>{{ item.name }}</span>
            <span></span>
          </div>
        </div>
        <div class="int-ul" v-for="(item, index) in intList" :key="index">
          <div class="int-li" v-if="intIndex == item.code">
            <span>{{ item.content }}</span>
            <van-swipe class="int-swipe" :autoplay="5000" :initial-swipe="intValue" indicator-color="rgba(67, 117, 245, 1)" :show-indicators="false">
              <van-swipe-item v-for="(item1, index1) in item.carousel" :key="index1">
                <img class="int-img" :src="item1" alt="">
              </van-swipe-item>
            </van-swipe>
            <div class="indicator">
              <div :class="['i-li', intValue == item1 ? 'i-on':'']" v-for="(item1, index1) in item.carousel" :key="index1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="three-chunck chunck">
      <div class="chunck-title">
        <span>低代码功能开发</span>
        <span>基于事件快速搭建适用各种行业、各种场景的表单、审批流程</span>
      </div>
      <div class="dev-ul">
        <div class="dev-li" v-for="(item, index) in devList" :key="index">
          <div class="title-c">
            <div class="dev-title">
              <span class="g-a-c">{{ item.name.substring(1,0) }}</span>
              <span>{{ item.name.substring(1,item.name.length) }}</span>
            </div>
            <div class="dev-c">
              <span>{{ item.content }}</span>
              <span v-if="item.content1">{{ item.content1 }}</span>
            </div>
          </div>
          <img class="dev-img" :src="require(`@/assets/images/home/dev-img${item.code}.png`)" alt="">
        </div>
      </div>
    </div>
    <div class="four-chunck chunck chunck-bg">
      <img class="bg" src="@/assets/images/home/four-bg.png" alt="">
      <div class="chunck-title">
        <span>平台化</span>
        <span>企业管理多流程整合，同一平台一体化运作</span>
      </div>
      <div class="platform-ul">
        <div :class="['platform-li', platformIndex == item.code ? 'li-on' : '']" v-for="(item, index) in platformList" :key="index" @click="modeClick(item, 'platformIndex')">
          <img class="li-img" :src="require(`@/assets/images/home/platform-li${item.code+1}.png`)" alt="">
          <span>{{ item.name }}</span>
        </div>
      </div>
      <van-swipe class="platform-swipe" :initial-swipe="platformIndex" :autoplay="5000" indicator-color="white" :show-indicators="false" @change="onChange($event,'platformIndex')">
        <van-swipe-item v-for="(item, index) in platformList" :key="index" >
          <img class="swipe-img" :src="item.url" alt="">
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="five-chunck chunck">
      <div class="chunck-title">
        <span>多终端，多平台</span>
        <span>多终端、多平台协同操作，办公、管理更便捷</span>
      </div>
      <div class="terminal">
        <div class="five-tag">
          <div :class="['tag-li', terminalIndex == item.code ? 'li-on' : '']" v-for="(item, index) in terminalList" :key="index" @click="modeClick(item,'terminalIndex')">
            <span>{{ item.name }}</span>
            <span></span>
          </div>
        </div>
        <van-swipe class="terminal-c" :initial-swipe="terminalIndex" :autoplay="5000" indicator-color="white" :show-indicators="false" @change="onChange($event,'terminalIndex')">
          <van-swipe-item v-for="(item, index) in terminalList" :key="index" >
            <div v-if="terminalIndex == item.code" class="li-c" >
              <div class="subhead"><span>{{ item.content }}</span></div>
              <img class="terminal-img" src="@/assets/images/home/terminal-img.png" alt="">
            </div>
          </van-swipe-item>
        </van-swipe>
        <!-- <div class="terminal-c" v-for="(item, index) in terminalList" :key="index">
          <div v-if="terminalIndex == item.code" class="li-c" >
            <div class="subhead"><span>{{ item.content }}</span></div>
            <img class="terminal-img" src="@/assets/images/home/terminal-img.png" alt="">
          </div>
        </div> -->
      </div>
    </div>
    <div class="six-chunck chunck">
      <div class="chunck-title">
        <span>行业方案</span>
      </div>
      <div class="industry-ul roll-ul" ref="industryRef" @touchmove="onTouchmove" @touchend="onTouchend">
        <div class="industry-li roll-li" v-for="(item, index) in industryList" :key="index" @click="detailClick(item)">
          <div v-if="item && item.previewTagImageUrls" class="hot">
            <img class="hot-img"  :src="item.previewTagImageUrls" alt="">
          </div>
          <div class="top-c">
            <img class="img" v-if="item && item.previewOfficialImageUrls && item.previewOfficialImageUrls.length > 0" :src="item.previewOfficialImageUrls[0]" alt="">
            <div class="tag" v-if="item && item.tagContent">
              <span :title="item.tagContent">
                {{ item.tagContent }}
              </span>
            </div>
          </div>
          <div class="contant">
            <div class="c-title">
              <span>{{ item.name }}</span>
            </div>
            <div class="c-p" v-html="item.brief"></div>
            <div class="btn g-a-c">
              <div class="btn-make g-a-c" @click.stop="detailClick(item)">
                <span>查看详情</span>
              </div>
              <div class="btn-tyan g-a-c" @click.stop="onChange(true, 'registerShow')">
                <span>立即体验</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="submitForm.offNext" class="roll-li view-more">
          <span>{{draggingOk ? '松 手 查 看':'左 滑 加 载'}}</span>

        </div>
        <div v-if="!submitForm.off && !submitForm.offNext" class="roll-li view-more">
          <span>没 有 更 多 了</span>
        </div>
        <div v-if="submitForm.off" class="after" :style="{'transform': `translateX(-${trans}px)`}"></div>
      </div>
    </div>
    <div class="seven-chunck chunck chunck-bg">
      <div class="chunck-title">
        <span>小程序</span>
      </div>
      <div class="mini-li li-1">
        <img class="bg" src="@/assets/images/home/mini-1.png" alt="">
        <div class="li-title">
          <span>和诚签</span>
        </div>
        <div class="tag">
          <span>电子签章 | 电子合同 | 数据存证 | 印控管理 | 防伪打印</span>
        </div>
        <div class="li-btn" @click="wxClick(1)">
          <span>戳这里，体验小程序</span>
        </div>
      </div>
      <div class="mini-li li-2">
        <img class="bg" src="@/assets/images/home/mini-2.png" alt="">
        <div class="li-title">
          <span>聚人气</span>
        </div>
        <div class="tag">
          <span>数字名片 | 名片分享 | 名片收藏 | 名片交换 | 浏览记录</span>
        </div>
        <div class="li-btn" @click="wxClick(2)">
          <span>戳这里，体验小程序</span>
        </div>
      </div>
    </div>
    <div class="eight-chunck chunck" id="videoChunck">
      <div class="chunck-title">
        <span>视频</span>
      </div>
      <div class="video-ul">
        <div class="video-li" v-for="(item, index) in videoList" :key="index">
          <div class="vadeo-top">
            <VideoNew :typePrice="1" :item="item" :keyId="keyId" @playClick="playClick"></VideoNew>
          </div>
          <div class="video-c">
            <div class="title">
              <span>{{ item.tags }}</span>
            </div>
            <div class="c-ul">
              <div class="c-li">
                <img class="li-img" src="@/assets/images/home/shijian_o.png" alt="">
                <span>{{ item.createTime.split(" ")[0].split("-")[0] }}.{{ item.createTime.split(" ")[0].split("-")[1] }}.{{ item.createTime.split(" ")[0].split("-")[2] }}</span>
              </div>
              <div class="c-li">
                <img class="li-img" src="@/assets/images/home/yanjing.png" alt="">
                <span>{{ item.likesNum }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="['more', moreShow ? 'more-d':'']" v-if="videoListQ.length > 4" @click="onChange(!moreShow, 'moreShow')">
        <span v-if="!moreShow">查看更多</span>
        <span v-if="moreShow">收起更多</span>
        <img class="more-img" src="@/assets/images/home/more.png" alt="">
      </div>
    </div>
    <div class="nine-chunck chunck chunck-bg">
      <div class="chunck-title">
        <span>部署方式</span>
        <span>支持多种部署方式，满足您的不同需求</span>
      </div>
      <div class="mode-ul roll-ul">
        <div class="mode-li roll-li" v-for="(item, index) in modeList" :key="index">
          <div class="mode-title">
            <img class="bg" src="@/assets/images/home/mode-bg.png" alt="">
            <span>{{ item.name }}</span>
          </div>
          <div class="mode-c">
            <div :class="['c-title']">
              <span>{{ item.title }}</span>
            </div>
            <div class="c-li" v-for="(item1, index1) in item.list" :key="index1">
              <img class="draw" src="@/assets/images/home/draw.svg" alt="" srcset="">
              <span>{{ item1 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ten-chunck chunck chunck-bg">
      <img class="bg" src="@/assets/images/home/ten-bg.png" alt="">
      <div class="chunck-title">
        <span>数据安全</span>
      </div>
      <div class="data-ul roll-ul">
        <div class="data-li roll-li" v-for="(item, index) in dataList" :key="index">
          <img class="bg" src="@/assets/images/home/ten-bg1.png" alt="">
          <img class="img" :src="require(`@/assets/images/home/data-li${item.code}.png`)" alt="">
          <span>{{ item.name }}</span>
          <p>{{ item.content }}</p>
        </div>
      </div>
    </div>
    <div class="eleven-chunck chunck">
      <div class="chunck-title">
        <span>典型用户</span>
      </div>
      <div class="eleven-ul">
        <div class="eleven-li" v-for="(item, index) in 9" :key="index">
          <img class="eleven-img" :src="require(`@/assets/images/home/customer-li${index + 1}.png`)" alt="">
        </div>
      </div>
    </div>
    <BottomDom ref="footerDom"></BottomDom>
    <Register :modalType="registerShow" @modalOff="modalOff"></Register>
  </div>
</template>

<script>
// import Js2WordCloud from 'js2wordcloud'
import WordCloud from 'wordcloud';
import { Toast } from 'vant'
import { getMenuMap, getSystemInfos, goodsGoodsPage, getOtherInfo, generatescheme } from '@/util/api'
export default {
  components: {
    BottomDom: resolve => (require(['@/views/components/bottom-dom/index.vue'], resolve)), // 底部
    Register: resolve => (require(['@/views/components/confirm-modal/register.vue'], resolve)), // 注册
    VideoNew: resolve => (require(['@/views/components/fiche/video.vue'], resolve)),
  },
  data() {
    return {
      registerShow: false,
      makeShow: false,
      bannerList: [
        require(`@/assets/images/home/banner/banner1.png`),
        require(`@/assets/images/home/banner/banner1.png`),
        require(`@/assets/images/home/banner/banner1.png`),
      ],
      serObj: {},
      serveList: [],
      intIndex: 0,
      intValue: 0,
      intList: [
        { code: 0,
          name: '智能风控',
          content: '关注您在意的合作伙伴，实时接收Ta的信用评分变动通知、工商司法预警，推送AIGC为您准备的企业风险报告。',
          carousel: [
            require(`@/assets/images/home/carousel-1-1.png`),
            require(`@/assets/images/home/carousel-1-2.png`)
          ]
        },
        { code: 1,
          name: '智能设备管理',
          content: '利用物模型及主流物联网协议（MQTT直链、网关转换等），管理企业生产或采购的物联网硬件产品，实现企业内部万物互联，全天候实时、时序列只能监控物联网设备。',
          carousel: [
            require(`@/assets/images/home/carousel-2-1.png`)
          ]
        },
        { code: 2,
          name: '企业运营大脑',
          content: '基于自有人事、业务数据及指标模型，利用深度学习算法、自我优化模型算法，动态分析企业运营情况，为企业健康运营保驾护航。',
          carousel: [
            require(`@/assets/images/home/carousel-3-1.png`)
          ]
        }
      ],
      devList: [
        { code: 1, name: '元数据管理', content: '支持配置表单元数据，可依据行业要求，', content1: '快速创建行业元数据库。' },
        { code: 2, name: '动态表单', content: '提供可视化的页面，拖拽控件快速创建表单。' },
        { code: 3, name: '动态审批流', content: '强大的流程处理中心，审批流程自定义配置。' }
      ],
      platformIndex: 0,
      platformList: [
        { code: 0, name: '统一工作平台', url: require('@/assets/images/home/platform-c-1.png') },
        { code: 1, name: '统一登录认证', url: require('@/assets/images/home/platform-c-2.png') },
        { code: 2, name: '统一组织机构', url: require('@/assets/images/home/platform-c-3.png') },
        { code: 3, name: '统一消息中心', url: require('@/assets/images/home/platform-c-4.png') },
        { code: 4, name: '统一流程中心', url: require('@/assets/images/home/platform-c-5.png') }
      ],
      terminalIndex: 0,
      terminalList: [
        {
          code: 0,
          name: 'PC端',
          content: '包含项目、人事、电子签、合同、资产、物联网、教育、客户关系、流程、消息等100多个功能模块。'
        },
        {
          code: 1,
          name: '小程序端',
          content: '包含人事、项目、电子签、教育、流程、消息等20多个功能模块。'
        },
        {
          code: 2,
          name: 'H5端',
          content: '支持电子签模块的H5端签署，接收即可签署，无需注册。'
        },
        {
          code: 3,
          name: '公众号端',
          content: '支持教育系统学员上课及练习等功能模块。'
        },
        {
          code: 4,
          name: '多平台',
          content: '对接企业微信、钉钉、⻜书等主流的第三⽅企业服务平台；支持平台应用直接安装或自建应用。'
        }
      ],
      touchX: 0,
      draggingOk: false,
      submitForm: {
        shelvesStatus: 2,
        page: {
          current: 1,
          size: 10
        },
        name: '',
        goodsClass: '1303000000',
        displayFalg: true,
        off: true, // 是否加载完
        offNext: true, // 是否有下一页
      },
      trans: 0,
      industryList: [],
      modeIndex: 1,
      modeList: [
        { code: 1,
          name: 'SAAS',
          title: '依托SAAS服务能力，提供一体化企业管理云服务',
          list: ['独立的SAAS服务', '投入成本低', '建设周期短，即租即用', '无需专人运维', '适用于中小微组织、个人使用']
        },
        { code: 2,
          name: '私有云部署',
          title: '数据本地化存储，按需定制系统私有化管理平台',
          // list: ['数据本地存储', '支持个性化需求定制开发', '业务签署场景相对复杂', '支持个性化签署模板设置', '实现独立运维及使用', '适用于中大型组织使用']
          list: ['数据本地存储', '支持个性化需求定制开发', '支持多种行业解决方案', '实现独立运维及使用', '适用于中大型组织使用']
        },
        { code: 3,
          name: '混合云部署',
          title: '公有云+私有云的混合使用',
          list: ['本地化轻量级部署', '数据本地存储', '建设周期最短1-3天', '适用于有一定开发能力的企业使用']
        }
      ],
      dataList: [
        { code: 1, name: '体系安全', content: '符合ISO27001和国家等保三级要求' },
        { code: 2, name: '业务安全', content: '支持功能级别权限按钮管控，支撑系统权限、审计权限、运维权限三权分离管控；多维度数据安全管控，支撑以角色为单位，配置数据可见范围' },
        { code: 3, name: '访问安全', content: '多要素认证，智能登录风险等级判断，兼顾登录体验和用户安全。实现人机校验、手机短信验证码登录及OAuth2.0第三方授权登录' },
        { code: 4, name: '链路安全', content: '用户端传输层数据加密，全站Https协议通信' }
      ],
      moreShow: false,
      videoList4: [],
      videoListQ: [],
      videoList: [],
      keyId: ''
    };
  },
  computed: {},
  created() {
  },
  async mounted () {
    await getMenuMap({}).then(res => {
      this.serObj = res
    })
    await getSystemInfos({}).then(data => {
      if (data.length > 0) {
        this.serveList = []
        data.map(item => {
          let obj = item
          obj.serList = []
          if (this.serObj[item.code] && this.serObj[item.code].length > 0) {
            this.serObj[item.code].map(item2 => {
              obj.serList.push([ item2.name, this.getSize()])
            })
          }
          this.serveList.push(obj)
        })
           this.serveList.map((item, index) => {
            let list = item.serList
            this.$nextTick(() => {
              // this.renderCloud(list,`word-cloud${index}`)
              const $el = document.getElementById(`word-cloud${index}`)
              var options = eval({
                "list": list,//或者[['各位观众',45],['词云', 21],['来啦!!!',13]],只要格式满足这样都可以
                "gridSize": 6, // 密集程度 数字越小越密集
                "weightFactor": 1, // 字体大小=原始大小*weightFactor
                "maxFontSize": 60, //最大字号
                "minFontSize": 14, //最小字号
                "fontWeight": 'normal', //字体粗细
                "fontFamily": 'Times, serif', // 字体
                // "color": 'random-light', // 字体颜色 'random-dark' 或者 'random-light'
                // "backgroundColor": '#333', // 背景颜色
                "rotateRatio": 1 // 字体倾斜(旋转)概率，1代表总是倾斜(旋转)
            });
              WordCloud($el, options);
            })
        })
      } else {
        this.serveList = []
      }
    })
    this.getOtherInfo()
    await this.goodsGoodsPage(1)
  },
  methods: {
    // renderCloud (words, id) {
    //   let w = window.screen.availWidth / 375 * 18
    //   var wc = new Js2WordCloud(document.getElementById(id))
    //   let list = words
    //   let option = {
    //     imageShape: require('@/assets/c-bg.png'),
    //     tooltip: {
    //       show: false,
    //       formatter: function (item) {
    //         console.log(item)
    //       }
    //     },
    //     backgroundColor: 'rgba(0, 0, 0, 0)',
    //     // fontSizeFactor: 90,  // 当词云值相差太大，可设置此值进字体行大小微调，默认0.1
    //     maxFontSize: w,      // 最大fontSize，用来控制weightFactor，默认60
    //     minFontSize: 12,      // 最大fontSize，用来控制weightFactor，默认60
    //     gridSize: 1,
    //     rotateRatio: 0.8,
    //     list: list,
    //     shape: 'pentagon',
    //     drawOutOfBound:true,
    //     ellipticity: 1,
    //     scale: 1
    //   }
    //   wc.setOption(option)
    //   window.onresize = function () {
    //     wc.setOption(option)
    //   }
    // },
    onTouchmove(e) {
      if (!this.submitForm.off && !this.submitForm.offNext) {
        return
      }
      const DRAG_MAX = 62; // 最大位移
      const DRAG_LINE = 20; // 触发松手事件的偏移量
      // console.log(e, 'onTouchmove')
      const pageX = e.changedTouches[0].pageX
      const listRefs = this.$refs.industryRef
      const scrollDistance = (listRefs.offsetWidth || 0) + (listRefs.scrollLeft || 0) - (listRefs.scrollwidth || 0);
      if (scrollDistance >= ((this.industryList.length - 1) * 260 )) {
        if (this.touchX === 0) {
          this.touchX = pageX
          return
        }
        const dragDistance = this.touchX - pageX
        this.trans = dragDistance < 0 ? 0 : (dragDistance > DRAG_MAX ? DRAG_MAX : dragDistance)
        this.draggingOk = this.trans > DRAG_LINE
      } else {
        if (this.trans > 0) this.trans = 0
        if (this.draggingOk) this.draggingOk = false
      }
    },
    onTouchend() {
      if (this.draggingOk) {
        if (this.submitForm.off) {
          return
        }
        if ((this.submitForm.off && !this.submitForm.offNext)) {
          return
        }
        if (this.submitForm.offNext) { // 触底刷新
          setTimeout(() => {
            this.submitForm.page.current++
            this.goodsGoodsPage()
          }, 1)
        }
      }
      this.touchX = 0
      this.trans = 0
      this.draggingOk = false
      // console.log(e, 'onTouchend')
    },
    detailClick (item) {
      this.$router.push({
        name: 'detail',
        query: {
          id: item.id
        }
      })
    },
    goodsGoodsPage(val) {
      if (val == 1) {
        this.submitForm.page.current = 1
        this.industryList = []
      }
      this.submitForm.off = true
      goodsGoodsPage(this.submitForm).then(async res => {
        this.submitForm.off = false
        if (res.records.length == this.submitForm.page.size) {
          this.submitForm.offNext = true
        } else if (res.records.length < this.submitForm.page.size) {
          this.submitForm.offNext = false
        }
        this.industryList.push(...res.records)
      })
    },
    getSize(){
      let w = window.screen.availWidth / 375 * 18
        return Math.floor(Math.random() * (w - 12) + 12);
    },
    modalOff (val) {
      if (!val) {
        this.registerShow = false
      }
    },
    onChange(index, key) {
      if (key == 'makeShow') {
        this.$refs.footerDom.modalOff(true)
        return
      }
      this[key] = index
      if (key == 'moreShow') {
        if (index) {
          this.videoList = JSON.parse(JSON.stringify(this.videoListQ))
        } else {
          const returnEle = document.getElementById('videoChunck');  //productId是将要跳转区域的id
          if ((!returnEle) == false) {
            returnEle.scrollIntoView(true); // true 是默认的
          }
          this.videoList = JSON.parse(JSON.stringify(this.videoListQ.slice(0,4)))
        }
      }
    },
    modeClick(item, key) {
      this[key] = item.code
      if (key == 'intIndex') {
        this.intValue = 0
      }
    },
    playClick(e) {
      this.keyId = e
    },
    getOtherInfo () {
      getOtherInfo({ key: 'video'}).then(res => {
        res.map(item => {
          item.keyId = this.getS()
        })
        this.videoListQ = res
        if (this.videoListQ.length > 4) {
          this.videoList = this.videoListQ.slice(0,4)
        } else {
          this.videoList = res
        }
      })
    },
    getS () { // 生成随机key
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) + '-' + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) + '-' + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    },
    wxClick (val) {
      if (val == 1) {
        generatescheme({}).then((data)=> {
          if (data) {
            location.assign(data)
          } else {
            Toast.fail('未配置');
          }

        })
      } else {
        generatescheme({appId: 'wx799c2196368f40d8'}).then((data)=> {
          if (data) {
            location.assign(data)
          } else {
            Toast.fail('未配置');
          }
        })
      }
      // if (val) {
      //   this.wxConfig('wx799c2196368f40d8')
      // }
    }
  }
};
</script>

<style lang='less' scoped>
.g-a-c {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home {
  width: 100%;
  background: rgba(248, 248, 248, 1);
  display: flex;
  flex-direction: column;
  .header {
    background: rgba(248, 248, 248, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 21px 15px 21px;
    .header-logo {
      width: 144px;
      height: 74px;
    }
    .make-btn {
      display: flex;
      padding: 16px 22px;
      background: #2E6CF6;
      border-radius: 6px;
      span {
        font-size: 26px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }
  .banner {
    width: 750px;
    height: 395px;
    .swipe-img {
      width: 750px;
      height: 395px;
    }
  }
  .chunck-bg {
    position: relative;
    z-index: 1;
  }
  .chunck {
    width: 750px;
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
    }
    .chunck-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 46px auto 48px auto;
      span:nth-child(1) {
        font-size: 36px;
        font-weight: 500;
        color: #181818;
      }
      span:nth-child(2) {
        display: flex;
        justify-content: center;
        text-align: center;
        padding: 0px 20px;
        font-size: 26px;
        font-weight: 400;
        color: #6A6E7C;
        margin-top: 21px;
        line-height: 50px;
      }
    }
    .roll-ul {
      width: 750px;
      display: flex;
      overflow-x: auto;
      .roll-li {
        flex-shrink: 0;
      }
      .roll-li:last-child {
        margin-right: 0px;
      }
    }
    .roll-ul::-webkit-scrollbar {
      width: 0rem;
    }
  }
  .one-chunck {
    background: #F9F9F9;
    .chunck-title {
      margin: 50px auto 39px auto;
    }
    .system-ul {
      width: 750px;
      display: flex;
      margin: 0px 0px 62px 0px;
      overflow-x: auto;
      padding: 20px 0px;
      .system-li {
        flex-shrink: 0;
        width: 500px;
        height: 603px;
        background: linear-gradient(-180deg, #FFFFFF, #DAE6FC);
        box-shadow: 0px 0px 10px 0px rgba(176,176,177,0.41);
        border-radius: 8px;
        margin-right: 29px;
        .li-title {
          position: relative;
          z-index: 1;
          width: 100%;
          height: 132px;
          .g-a-c;
          .li-img {
            width: 54px;
            height: 54px;
            margin-right: 16px;
          }
          span {
            font-size: 36px;
            font-weight: 500;
            color: #000000;
          }
        }
        .word {
          width: 500px;
          height: 280px;
          background-color: rgba(255, 255, 255, 0) !important;
        }
        .btn {
          margin-top: 50px;
          width: 100%;
          .btn-make {
            padding: 22px 32px;
            background: #2277F8;
            border-radius: 6px;
            margin-right: 30px;
            span {
              font-size: 34px;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
          .btn-tyan {
            padding: 22px 32px;
            border: 1px solid #2277F8;
            border-radius: 6px;
            span {
              font-size: 34px;
              font-weight: 400;
              color: #2277F8;
            }
          }
        }
      }
      .system-li:nth-child(1) {
        margin-left: 21px;
      }
    }
    .system-ul::-webkit-scrollbar {
      width: 0rem;
    }
  }
  .two-chunck {
    padding-bottom: 49px;
    background: linear-gradient(-180deg, #FFFFFF, #DAE6FC);
    .int-c {
      width: 708px;
      background: #FFFFFF;
      margin: 0 auto;
      .int-bar {
        display: flex;
        justify-content: space-between;
        padding: 34px 57px 0px 71px;
        .bar-li {
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            font-size: 26px;
            font-weight: 400;
            color: #787878;
          }
          span:nth-child(2) {
            width: 62px;
            height: 5px;
            background: rgba(46, 108, 246, 0);
            margin-top: 16px;
          }
        }
        .li-on {
          span {
            color: #181818;
          }
          span:nth-child(2) {
            background: rgba(46, 108, 246, 1);
          }
        }
      }
      .int-ul {
        // padding: 45px 29px 35px 29px;
        .int-li {
          display: flex;
          flex-direction: column;
          padding: 45px 29px 0px 29px;
          span {
            font-size: 24px;
            font-weight: 400;
            color: #696D7A;
            line-height: 40px;
            margin-bottom: 32px;
            min-height: 120px;
          }
          .int-swipe{
            position: relative;
            width: 650px;
            height: 398px;
            border-radius: 20px;
            .int-img {
              width: 650px;
              height: 398px;
            }
          }
          .indicator {
            display: flex;
            margin: 23px auto 35px auto;
            .i-li {
              width: 16px;
              height: 6px;
              background: #E6E7E8;
              border-radius: 3px;
              margin-right: 9px;
            }
            .i-li:last-child {
              margin-right: 0px;
            }
            .i-on {
              background: rgba(67, 117, 245, 1);
              width: 36px;
            }
          }
        }
      }
    }

  }
  .three-chunck {
    background: linear-gradient(-180deg, #FFFFFF, #DAE6FC);
    .chunck-title {
      margin: 52px auto 31px auto;
    }
    .dev-ul {
      display: flex;
      margin: 0px 0px 11px 0px;
      overflow-x: auto;
      padding: 20px 0px 20px 21px;
      .dev-li {
        flex-shrink: 0;
        width: 512px;
        background: #FFFFFF;
        border-radius: 12px;
        margin-right: 30px;
        padding-bottom: 57px;
        .title-c {
          .dev-title {
            display: flex;
            align-items: center;
            margin: 51px 0px 36px 32px;
            span:nth-child(1) {
              width: 62px;
              height: 62px;
              background: linear-gradient(0deg, #2857F4, #5787F7);
              box-shadow: 0px 0px 20px 0px rgba(51,78,143,0.44);
              border-radius: 50%;
              margin-right: 15px;

              font-size: 32px;
              font-weight: 500;
              color: #FFFFFF;
              margin-right: 8px;
            }
            span:nth-child(2) {
              font-size: 30px;
              font-weight: 500;
              color: #2E6CF6;
            }
          }
          .dev-c {
            margin: 0px 28px 41px 28px;
            span {
              font-size: 24px;
              font-weight: 400;
              color: #6A6D79;
              line-height: 43px;
            }
          }
        }
        .dev-img {
          margin-left: 20px;
          width: 472px;
          height: 287px;
          border-radius: 12px;
          box-shadow: 0px 0px 10px 0px rgba(176,176,177,0.41);
        }
      }
    }
    .dev-ul::-webkit-scrollbar {
      width: 0rem;
    }
  }
  .four-chunck {
    padding-bottom: 44px;
    .chunck-title {
      margin: 46px auto 45px auto;
    }
    .platform-ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0px 20px;
      .platform-li {
        width: 228px;
        height: 82px;
        background: #FFFFFF;
        box-shadow: 0px 0px 40px 0px rgba(161,161,161,0.42);
        margin-right: 12px;
        border-bottom: 3px solid rgba(2, 91, 255, 0);
        margin-bottom: 10px;
        .g-a-c;
        .li-img {
          width: 48px;
          height: 48px;
          margin-right: 9px;
        }
        span {
          font-size: 22px;
          font-weight: 400;
          color: #000000;
        }
      }
      .li-on {
        border-bottom: 3px solid rgba(2, 91, 255, 1);
      }
      .platform-li:nth-child(3n) {
        margin-right: 0px;
      }
    }
    .platform-swipe {
      width: 695px;
      height: 347px;
      margin: 47px auto 0px auto;
      .swipe-img {
        width: 695px;
        height: 347px;
      }
    }
  }
  .five-chunck {
    background: #F0F0F1;
    .terminal {
      padding: 47px 38px 58px 48px;
      background: #FFFFFF;
      .five-tag {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(227, 227, 227, 1);
        margin-bottom: 47px;
        .tag-li {
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            font-size: 32px;
            font-weight: 400;
            color: #787878;
          }
          span:nth-child(2) {
            width: 62px;
            height: 5px;
            background: rgba(2, 91, 255, 0);
            margin-top: 11px;
            transform: translateY(1px);
          }
        }
        .li-on {
          span {
            font-size: 32px;
            font-weight: 500;
            color: #000000;
          }
          span:nth-child(2) {
            background: rgba(2, 91, 255, 1);
          }
        }
      }
      .terminal-c {
        .li-c {
          .subhead {
            height: 68px;
            span {
              font-size: 24px;
              font-weight: 400;
              color: #7B7B7B;
              line-height: 40px;
            }
          }
          .terminal-img {
            margin-top: 33px;
            width: 654px;
            height: 416px;
            box-shadow: 0px 0px 24px 0px rgba(152,152,152,0.58);
            border-radius: 14px;
          }
        }
      }
    }
  }
  .six-chunck {
    background: #F0F0F1;
    padding-bottom: 15px;
    .chunck-title {
      margin: 57px auto 33px auto;
    }
    .industry-ul {
      position: relative;
      transition: all 0.1s;
      box-sizing: border-box;
      padding: 20px;
      .industry-li {
        position: relative;
        width: 504px;
        background: #FFFFFF;
        box-shadow: 0px 0px 36px 0px rgba(183,183,183,0.24);
        border-radius: 6px;
        margin-right: 37px;
        .hot {
          position: absolute;
          top: -12px;
          right: -13px;
          width: 119px;
          height: 118px;
          z-index: 9;
          .hot-img {
            width: 119px;
            height: 118px;
          }
        }
        .top-c {
          position: relative;
          height: 400px;
          z-index: 1;
          .img {
            width: 100%;
            height: 400px;
          }
          .tag {
            position: absolute;
            box-sizing: border-box;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 10px;
            background: rgba(255, 240, 227, 0.9);
            border-radius: 2px;
            span {
              font-size: 24px;
              font-weight: 500;
              color: #ED732E;
              line-height: 18px;
            }
          }
        }
        .contant {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 26px 22px 0px 22px;
          .c-title {
            width: 100%;
            span:nth-child(1) {
              font-size: 30px;
              font-weight: 500;
              color: #000000;
            }
          }
          .c-p {
            width: 100%;
            min-height: 173px;
            margin-top: 22px;
            margin-bottom: 43px;
            word-break: break-all;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            font-size: 24px !important;
            font-weight: 400;
            color: #696969;
            line-height: 44px;
          }
          .btn {
            // left: 28px;
            margin: auto 0px 57px 0px;
            display: flex;
            justify-content: center;
            .btn-make {
              padding: 16px 40px;
              background: #2277F8;
              border-radius: 6px;
              margin-right: 23px;
              span {
                font-size: 26px;
                font-weight: 400;
                color: #FFFFFF;
              }
            }
            .btn-tyan {
              padding: 16px 40px;
              border: 1px solid #2277F8;
              border-radius: 6px;
              span {
                font-size: 26px;
                font-weight: 400;
                color: #2277F8;
              }
            }
          }
        }
      }
      .view-more {
        display: flex;
        align-items: center;
        span {
          writing-mode: vertical-rl;
          line-height: 17px;
        }
      }
      .after{
        position: absolute;
        width: 100%;
        right: -100%;
        top: 0;
        z-index: -1;
        height: 100%;
        background: #F0F0F1; /* 与“查看更多”同色 */
      }
    }
  }
  .seven-chunck {
    box-sizing: border-box;
    padding: 0px 22px 56px 22px;
    background: #FFFFFF;
    .mini-li {
      box-sizing: border-box;
      position: relative;
      width: 708.2px;
      margin-bottom: 28px;
      display: flex;
      flex-direction: column;
      padding: 41px 0px 34px 44px;
      .li-title {
        span {
          font-size: 34px;
          font-weight: 500;
        }
      }
      .tag {
        margin: 17px 0px 28px 0px;
        span {
          font-size: 22px;
          font-weight: 400;
          color: #606983;
        }
      }
      .li-btn {
        .g-a-c;
        width: 266px;
        height: 58px;
        background: linear-gradient(90deg, #82B2EF, #6096D1);
        border-radius: 29px;
        span {
          font-size: 24px;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }
    .mini-li:last-child {
      margin-bottom: 0px;
    }
    .li-1 {
      .li-title {
        span {
          color: #444F6F;
        }
      }
      .tag {
        span {
          font-size: 22px;
          font-weight: 400;
          color: #606983;
        }
      }
    }
    .li-2 {
      .li-title {
        span {
          color: #8B6320;
        }
      }
      .tag {
        span {
          font-size: 22px;
          font-weight: 400;
          color: #9E7C47
        }
      }
      .li-btn {
        background: linear-gradient(90deg, #F1CD99, #DB9A3D);
      }
    }
  }
  .eight-chunck {
    background: rgba(240, 240, 241, 1);
    .video-ul {
      display: flex;
      flex-wrap: wrap;
      width: 705px;
      margin: 0 auto;
      .video-li {
        overflow: hidden;
        width: 338px;
        // width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 0px 36px 0px rgba(183,183,183,0.24);
        border-radius: 8px;
        margin-right: 29px;
        margin-bottom: 25px;
        .vadeo-top {
          height: 190px;
          // height: auto;
        }
        .video-c {
          padding: 22px 31px 27px 22px;
          .title {
            margin-bottom: 27px;
            span {
              font-size: 28px;
              font-weight: 500;
              color: #181818;
            }
          }
          .c-ul {
            display: flex;
            justify-content: space-between;
            .c-li {
              display: flex;
              align-items: center;
              .li-img {
                width: 24px;
                height: 24px;
                margin-right: 8px;
              }
              span {
                font-size: 22px;
                font-weight: 400;
                color: #868686;
              }
            }
            .c-li:nth-child(1) {
              width: 65%;
            }
            .c-li:nth-child(2) {
              width: 20%;
            }
          }
        }
      }
      .video-li:nth-child(2n) {
        margin-right: 0px;
      }
    }
    .more {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 22px 0px 48px;
      span {
        font-size: 24px;
        font-weight: 400;
        color: #A2A3A4;
      }
      .more-img {
        width: 24px;
        height: 24px;
        margin-left: 7px;
      }
    }
    .more-d {
      .more-img {
        transform: rotateZ(180deg);
      }
    }
  }
  .nine-chunck {
    // margin-top: 46px;
    background: #FFFFFF;
    padding-bottom: 31px;
    .mode-ul {
      display: flex;
      box-sizing: border-box;
      padding: 20px;
      .mode-li {
        margin-right: 22px;
        width: 436px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px 0px rgba(147,147,147,0.41);
        .mode-title {
          .g-a-c;
          position: relative;
          width: 100%;
          height: 80px;
          background: linear-gradient(90deg, #0036FF, #005AFF, #2692FF);
          opacity: 1;
          span {
            font-size: 30px;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
        .mode-c {
          display: flex;
          flex-direction: column;
          padding: 21px 24px 71px 24px;
          .c-title {
            display: flex;
            // align-items: center;
            margin: 21px 0px 39px 0px;
            height: 70px;
            span {
              font-size: 26px;
              font-weight: 500;
              color: #000000;
            }
          }
          .c-li {
            display: flex;
            margin-bottom: 27px;
            .draw {
              width: 22px;
              height: 19px;
              margin: 6px 16px 0px 0px;
            }
            span:nth-child(2) {
              font-size: 22px;
              font-weight: 400;
              color: #48505A;
            }
          }
          .c-li:last-child {
            margin-bottom: 0px;
          }
        }
      }
      .mode-li:last-child {
        margin-right: 0rem;
      }
    }
  }
  .ten-chunck {
    padding-bottom: 31px;
    .chunck-title {
      margin: 46px auto 34px auto;
      span:nth-child(1) {
        font-size: 36px;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
    .data-ul {
      box-sizing: border-box;
      padding: 20px;
      .data-li {
        position: relative;
        width: 345px;
        background: #FFFFFF;
        border-radius: 4px;
        margin-right: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 22px  40px 43px 36px;
        .bg {
          z-index: 0;
        }
        .img {
          width: 88px;
          height: 88px;
        }
        span {
          font-size: 26px;
          font-weight: 500;
          color: #000000;
          margin: 9px 0px 26px 0px;
        }
        p {
          font-size: 22px;
          font-weight: 400;
          color: #696F77;
          line-height: 40px;
        }
      }
      .data-li:last-child {
        margin-right: 0px;
      }
    }
  }
  .eleven-chunck {
    background: #F0F0F1;
    .eleven-ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0px 21px;
      padding-bottom: 54px;
      .eleven-li {
        width: 230px;
        height: 101px;
        margin-bottom: 7px;
        background: #FFFFFF;
        .eleven-img {
          width: 230px;
          height: 101px;
        }
      }
    }
  }
}
</style>